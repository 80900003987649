import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconInsertColumn: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "insert column"}>
      <svg viewBox="0 0 32 32">
        <path d="M7 16.414l-1.293 1.293a1 1 0 11-1.414-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L9 16.414V22a2 2 0 001.85 1.995L11 24h5v-4h-2a1 1 0 010-2h2v-4h-2a1 1 0 010-2h2V8h-5a2 2 0 00-1.995 1.85L9 10a1 1 0 01-1.993.117L7 10a4 4 0 013.8-3.995L11 6h12a4 4 0 013.995 3.8L27 10v12a4 4 0 01-3.8 3.995L23 26H11a4 4 0 01-3.995-3.8L7 22v-5.586zM25 20h-7v4h5a2 2 0 001.995-1.85L25 22v-2zm0-6h-7v4h7v-4zm-2-6h-5v4h7v-2a2 2 0 00-2-2z" />
      </svg>
    </Icon>
  );
};

export default IconInsertColumn;
