import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconNewtab: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "newtab"}>
      <svg viewBox="0 0 32 32">
        <path d="M11 6a1 1 0 010 2H8v16h16v-3l.007-.117A1 1 0 0126 21v4a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 011-1zm14 0a1 1 0 011 1v8a1 1 0 01-2 0V9.414L13.707 19.707a1 1 0 01-1.414-1.414L22.586 8H17a1 1 0 010-2h8z" />
      </svg>
    </Icon>
  );
};

export default IconNewtab;
