import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCancel: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "cancel"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm6.32 5.095L11.096 22.321A8 8 0 0022.32 11.096zM16 8a8 8 0 00-6.32 12.905L20.904 9.679A7.965 7.965 0 0016 8z" />
      </svg>
    </Icon>
  );
};

export default IconCancel;
