import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconNanodegree: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "nanodegree"}>
      <svg viewBox="0 0 32 32">
        <path d="M19.566 21h5.693l-5.25-9-2.85 4.988L19.566 21zM20 9a.99.99 0 01.864.496l7 12A1 1 0 0127 23h-8a1 1 0 01-.857-.486l-2.144-3.572-2.142 3.572A1 1 0 0113 23H5a1 1 0 01-.864-1.504l7-12a.988.988 0 01.739-.488L12 9h8zm-8.009 3l-5.25 9h5.693l2.407-4.012L11.991 12zm6.285-1h-4.554L16 14.984 18.276 11z" />
      </svg>
    </Icon>
  );
};

export default IconNanodegree;
