import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconEmail: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "email"}>
      <svg viewBox="0 0 32 32">
        <path d="M24.007 9C25.108 9 26 9.904 26 11.002v9.996C26 22.1 25.11 23 24.007 23H7.993A2.002 2.002 0 016 20.998v-9.996C6 9.9 6.89 9 7.993 9h16.014zM7.999 11.747L8 20.997 24.001 21 24 11.39l-4.195 4.007 1.902 1.896a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.32.083l-.094-.083-2.1-2.102L16.6 17.8a1 1 0 01-1.09.072l-.11-.072-1.6-1.2-.044.055-.049.052-2 2a1 1 0 01-1.497-1.32l.083-.094 1.898-1.9-4.192-3.646zM16 16l7-4.998L9 11l7 5z" />
      </svg>
    </Icon>
  );
};

export default IconEmail;
