import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSummary: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "summary"}>
      <svg viewBox="0 0 32 32">
        <path d="M14.968 4.268a2.12 2.12 0 012.064 0l8.936 5A1.99 1.99 0 0127 11v10a1.99 1.99 0 01-1.032 1.732l-8.936 5a2.12 2.12 0 01-2.064 0l-8.936-5A1.99 1.99 0 015 21V11a1.99 1.99 0 011.032-1.732zm-7.905 7.961v7.387l3.583-1.992a1 1 0 011.342.357l.023.038a.976.976 0 01-.361 1.357l-3.757 2.089 7.066 3.953v-8.801l-7.896-4.388zm17.873 0l-7.897 4.388.001 8.801 6.996-3.914-3.825-2.128a.976.976 0 01-.362-1.357l.023-.038a1 1 0 011.343-.357l3.721 2.069v-7.464zM17.04 6.582V11a1 1 0 01-1 1h-.08a1 1 0 01-1-1l-.001-4.418-6.926 3.876 7.966 4.428 7.967-4.429-6.926-3.875z" />
      </svg>
    </Icon>
  );
};

export default IconSummary;
