import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconArrowLeftSmall: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "arrow left_small"}>
      <svg viewBox="0 0 32 32">
        <path d="M9 16v.02c0 .023.002.046.004.07L9 16a1.008 1.008 0 00.213.617l.007.008.073.082 6 6a1 1 0 001.414-1.414L12.414 17H22a1 1 0 000-2h-9.586l4.293-4.293a1 1 0 10-1.414-1.414l-6 6-.073.082a1.005 1.005 0 00-.007.008l.08-.09a1.008 1.008 0 00-.293.689V16z" />
      </svg>
    </Icon>
  );
};

export default IconArrowLeftSmall;
