import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconReports: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "reports"}>
      <svg viewBox="0 0 32 32">
        <path d="M25.087 6a2 2 0 011.314.492l.131.126 1.913 2a2 2 0 01.548 1.211L29 10v2a2 2 0 01-1.85 1.995L27 14v11a2 2 0 01-1.85 1.995L25 27H7a2 2 0 01-1.995-1.85L5 25V14a2 2 0 01-1.995-1.85L3 12v-2a2 2 0 01.442-1.254l.113-.128 1.913-2a2 2 0 011.264-.61L6.913 6h18.174zM25 14H7v11h18V14zm-2.5 2a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h1zm-4 2a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1zm-4 2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h1zm-4 1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1zM27 10H5v2h22v-2zm-1.913-2H6.913l-.956 1h20.086l-.956-1z" />
      </svg>
    </Icon>
  );
};

export default IconReports;
