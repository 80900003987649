import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSheetplus: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "sheetplus"}>
      <svg viewBox="0 0 32 32">
        <path d="M21 6a2 2 0 012 2v7a1 1 0 01-1.993.117L21 15V8H9v16h6a1 1 0 01.117 1.993L15 26H9a2 2 0 01-2-2V8a2 2 0 012-2h12zm0 12a1 1 0 011 1v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2h-2a1 1 0 010-2h2v-2a1 1 0 011-1zm-7 1a1 1 0 010 2h-2a1 1 0 010-2h2zm2-4a1 1 0 010 2h-4a1 1 0 010-2h4zm2-4a1 1 0 010 2h-6a1 1 0 010-2h6z" />
      </svg>
    </Icon>
  );
};

export default IconSheetplus;
