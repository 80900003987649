import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconTasklist: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "tasklist"}>
      <svg viewBox="0 0 32 32">
        <path d="M25 4c1.381 0 2.5 1.343 2.5 3s-1.119 3-2.5 3c-.17 0-.338-.02-.5-.06V25c0 1.657-1.119 3-2.5 3H7.5a2 2 0 01-1.413-.585C5.407 26.735 5 25.923 5 25s.406-1.735 1.087-2.415a2 2 0 011.238-.577L7.5 22V7a3 3 0 012.824-2.995L10.5 4H25zm-5.35 19.974l-.007.026H7.5c-.333.333-.5.667-.5 1 0 .292.128.583.383.875L7.5 26l12.143.001.01.032A3.535 3.535 0 0119.5 25c0-.36.053-.706.15-1.026zM22 24c-.187 0-.5.375-.5 1s.313 1 .5 1c.188 0 .5-.375.5-1s-.312-1-.5-1zm.5-18h-12a1 1 0 00-.993.883L9.5 7v15H22c.189 0 .373.025.55.073l-.049-.013V6zm-3.357 2.486a1 1 0 111.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294zM25 6c-.187 0-.5.375-.5 1s.313 1 .5 1c.188 0 .5-.375.5-1s-.312-1-.5-1z" />
      </svg>
    </Icon>
  );
};

export default IconTasklist;
