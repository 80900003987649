import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconArrowUp: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "arrow up"}>
      <svg viewBox="0 0 32 32">
        <path d="M15.293 6.293a1 1 0 011.414 0l8 8a1 1 0 01-1.414 1.414L17 9.414V25a1 1 0 01-.883.993L16 26a1 1 0 01-1-1V9.414l-6.293 6.293a1 1 0 01-1.32.083l-.094-.083a1 1 0 010-1.414z" />
      </svg>
    </Icon>
  );
};

export default IconArrowUp;
