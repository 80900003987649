import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCloseCircled: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "close circled"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.707 5.707L17.414 16l2.293 2.293c.943.943-.471 2.357-1.414 1.414L16 17.415l-2.293 2.292c-.943.943-2.357-.471-1.414-1.414L14.586 16l-2.293-2.293c-.943-.943.471-2.357 1.414-1.414L16 14.585l2.293-2.292c.943-.943 2.357.471 1.414 1.414z" />
      </svg>
    </Icon>
  );
};

export default IconCloseCircled;
