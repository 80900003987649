import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconReviews: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "reviews"}>
      <svg viewBox="0 0 32 32">
        <path d="M8 19v4h4v-4H8zm4-2a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4a2 2 0 012-2h4zm10 5a1 1 0 010 2h-2a1 1 0 010-2h2zm-5 0a1 1 0 110 2 1 1 0 010-2zm8-4a1 1 0 010 2h-8a1 1 0 010-2h8zM13.514 6.143a1 1 0 01.343 1.371l-3.6 6a1 1 0 01-1.564.193l-2.4-2.4a1 1 0 011.414-1.414l1.494 1.494 2.942-4.901a1 1 0 011.371-.343zM22 11a1 1 0 010 2h-2a1 1 0 010-2h2zm-5 0a1 1 0 110 2 1 1 0 010-2zm8-4a1 1 0 010 2h-8a1 1 0 010-2h8z" />
      </svg>
    </Icon>
  );
};

export default IconReviews;
