import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSettings: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "settings"}>
      <svg viewBox="0 0 32 32">
        <path d="M4.031 14.022c0-1.104.89-1.99 2.003-1.99l.83-.001a9.992 9.992 0 011.187-2.027l-.384-.667a2.002 2.002 0 01.722-2.73l3.48-2.009a1.99 1.99 0 012.726.74l.429.743a10.119 10.119 0 011.724-.025l.416-.719a2.002 2.002 0 012.725-.739l3.48 2.01a1.99 1.99 0 01.723 2.73l-.255.442a9.998 9.998 0 011.362 2.25l.83.001c1.101 0 2.002.895 2.002 1.99v4.02a1.99 1.99 0 01-2.003 1.99h-.83a9.997 9.997 0 01-1.34 2.226l.234.406a2.002 2.002 0 01-.723 2.73l-3.48 2.009a1.99 1.99 0 01-2.725-.74l-.38-.659a10.142 10.142 0 01-1.794-.025l-.395.685a2.002 2.002 0 01-2.726.739l-3.48-2.01a1.99 1.99 0 01-.722-2.73l.363-.631a9.991 9.991 0 01-1.167-2h-.829a2.002 2.002 0 01-2.003-1.99v-4.02zm2 4.018c0-.003.363-.006 1.088-.007l.517-.001H8.283a7.993 7.993 0 002.196 3.76l-1.08 1.87 3.47 2.008c-.004-.003.36-.64 1.092-1.91a8.025 8.025 0 003.875.067l1.06 1.836 3.473-2.003c-.004.003-.332-.559-.983-1.685a7.999 7.999 0 002.393-3.944h2.25v-.445-.105l.001-1.232v-.203l.001-2.024c0 .003-.266.005-.8.007H24.783l-.356.001h-.194l-.356.001h-.097a7.998 7.998 0 00-2.418-3.966l.999-1.728-3.47-2.007c.004.003-.358.636-1.086 1.899a8.013 8.013 0 00-3.812.065l-1.13-1.957L9.39 8.34c.005-.003.376.634 1.114 1.91a7.982 7.982 0 00-2.22 3.78l-2.249.001v.446l-.001.104v1.435L6.03 17.75v.291zm6-2.009a4 4 0 118 0 4 4 0 01-8 0zm2 0a2 2 0 104 0 2 2 0 00-4 0z" />
      </svg>
    </Icon>
  );
};

export default IconSettings;
