import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSubtractCircled: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "subtract circled"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm4 7a1 1 0 010 2h-8a1 1 0 010-2h8z" />
      </svg>
    </Icon>
  );
};

export default IconSubtractCircled;
