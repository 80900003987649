import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCelebrate: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "celebrate"}>
      <svg viewBox="0 0 32 32">
        <path d="M11.707 9.293l11 11a1 1 0 01-.409 1.661l-16 5a1 1 0 01-1.252-1.252l5-16a1 1 0 011.661-.41zM10 16.553l-2.476 7.923L10 23.702v-7.149zm2-4.14v10.664l2.094-.654A.996.996 0 0114 22v-7c0-.17.042-.33.117-.47L12 12.414zm4 4.001v5.413l4.124-1.289L16 16.414zM26 15a1 1 0 010 2h-4a1 1 0 010-2h4zm-.293-8.707a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0zM16 5a1 1 0 011 1v4a1 1 0 01-2 0V6a1 1 0 011-1z" />
      </svg>
    </Icon>
  );
};

export default IconCelebrate;
