import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconChecked: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "checked"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z" />
      </svg>
    </Icon>
  );
};

export default IconChecked;
