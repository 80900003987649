import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCheck: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "check"}>
      <svg viewBox="0 0 32 32">
        <path d="M9.271 18.758a1 1 0 011.415-1.414l4.028 4.028 7.35-12.73a1 1 0 011.261-.418l.105.053a1 1 0 01.366 1.366l-8 13.856a1.02 1.02 0 01-.011.02l-.025.037a1.115 1.115 0 01-.125.151l.062-.066a.994.994 0 01-.06.065l-.005.005a.872.872 0 01-.078.07l-.025.02a.93.93 0 01-.118.076l-.026.013a.959.959 0 01-.185.073c-.006 0-.013.002-.02.004a.746.746 0 01-.089.02c-.005 0-.011 0-.017.002a.695.695 0 01-.081.01h-.036a.65.65 0 01-.076 0l-.03-.003a.989.989 0 01-.63-.288z" />
      </svg>
    </Icon>
  );
};

export default IconCheck;
