import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconNotebook: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "notebook"}>
      <svg viewBox="0 0 32 32">
        <path d="M23.444 6C24.304 6 25 6.716 25 7.6v16.8c0 .884-.696 1.6-1.556 1.6H8.556C7.696 26 7 25.284 7 24.4V7.6C7 6.716 7.696 6 8.556 6h14.888zM11 8H9v16h2V8zm12 0H13v16h10V8zm-7 3h4a1 1 0 010 2h-4a1 1 0 010-2zm0 4h2a1 1 0 010 2h-2a1 1 0 010-2z" />
      </svg>
    </Icon>
  );
};

export default IconNotebook;
