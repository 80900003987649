import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconClassroom: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "classroom"}>
      <svg viewBox="0 0 32 32">
        <path d="M16.103 4.011l.083.025 5 2a.5.5 0 01.083.887l-.083.042L16.5 8.838v.884c.076.022.152.054.224.095l6.928 4a1 1 0 01.448 1.184L27 15a2 2 0 011.995 1.85L29 17v2a2 2 0 01-1.85 1.995L27 21v6a1 1 0 01-2 0v-6h-3v6a1 1 0 01-2 0V17a2 2 0 011.731-1.982L16.02 11.72l-5.718 3.303a2 2 0 011.693 1.828L12 17v10a1 1 0 01-2 0v-6H7v6a1 1 0 01-2 0v-6a2 2 0 01-1.995-1.85L3 19v-2a2 2 0 011.85-1.995L5 15h2.94a1 1 0 01.449-1.183l6.928-4c.06-.034.12-.061.183-.082V4.5a.5.5 0 01.603-.489zM17.5 23a.5.5 0 01.5.5v4a.5.5 0 11-1 0V24h-2v3.5a.5.5 0 11-1 0v-4a.5.5 0 01.5-.5h3zm-9 2a.5.5 0 01.5.5v1a.5.5 0 11-1 0v-1a.5.5 0 01.5-.5zm15 0a.5.5 0 01.5.5v1a.5.5 0 11-1 0v-1a.5.5 0 01.5-.5zm-15-3a.5.5 0 01.5.5v1a.5.5 0 11-1 0v-1a.5.5 0 01.5-.5zm15 0a.5.5 0 01.5.5v1a.5.5 0 11-1 0v-1a.5.5 0 01.5-.5zM16 15a2 2 0 110 4 2 2 0 010-4zm-6 2H5v2h5v-2zm17 0h-5v2h5v-2zm-11-1a1 1 0 100 2 1 1 0 000-2zm.5-10.762v2.523l3.153-1.26L16.5 5.237z" />
      </svg>
    </Icon>
  );
};

export default IconClassroom;
