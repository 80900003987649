import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSidePanelClose: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "SidePanelClose"}>
      <svg viewBox="0 0 32 32">
        <path d="M6 15a1 1 0 000 2h13a1 1 0 000-2zm3-7a1 1 0 000 2h14a1 1 0 100-2zm0 14a1 1 0 000 2h14a1 1 0 000-2zm-2.707-9.707l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L5.414 16l2.293-2.293a1 1 0 00-1.414-1.414zM23 15a1 1 0 100 2 1 1 0 000-2z" />
      </svg>
    </Icon>
  );
};

export default IconSidePanelClose;
