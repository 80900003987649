import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCaretUp: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "caret up"}>
      <svg viewBox="0 0 32 32">
        <path d="M11.902 20h8.196a.5.5 0 00.424-.765l-4.098-6.557a.5.5 0 00-.848 0l-4.098 6.557a.5.5 0 00.424.765z" />
      </svg>
    </Icon>
  );
};

export default IconCaretUp;
