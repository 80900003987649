import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconAdd: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "add"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 7a1 1 0 011 1v7h7a1 1 0 01.993.883L25 16a1 1 0 01-1 1h-7v7a1 1 0 01-.883.993L16 25a1 1 0 01-1-1v-7H8a1 1 0 01-.993-.883L7 16a1 1 0 011-1h7V8a1 1 0 01.883-.993z" />
      </svg>
    </Icon>
  );
};

export default IconAdd;
