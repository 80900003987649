import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconArrowLeft: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "arrow left"}>
      <svg viewBox="0 0 32 32">
        <path d="M14.293 7.293a1 1 0 011.414 1.414L9.414 15H25a1 1 0 01.993.883L26 16a1 1 0 01-1 1H9.414l6.293 6.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0l-8-8a1 1 0 010-1.414z" />
      </svg>
    </Icon>
  );
};

export default IconArrowLeft;
