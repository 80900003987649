import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCopy: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "copy"}>
      <svg viewBox="0 0 32 32">
        <path d="M23.998 6C25.102 6 26 6.902 26 8.002v9.996A2.007 2.007 0 0123.998 20H20v4a2 2 0 01-2 2H8a2 2 0 01-2-2V14a2 2 0 012-2h4V8.002C12 6.898 12.902 6 14.002 6h9.996zM12 14H8v10h10v-4h-3.998A2.007 2.007 0 0112 17.998V14zm3.939-6h-1.937l.001.266v4.835l-.001.295-.001 2.303v.362L14 17.998l.266-.001h4.835l.295.001 2.303.001h1.895l.404.001-.001-.266V15.985 12.899l.001-.295.001-2.303V8.406L24 8.002l-.266.001h-4.835l-.295-.001-2.303-.001h-.362z" />
      </svg>
    </Icon>
  );
};

export default IconCopy;
