import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconRubix: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "rubix"}>
      <svg viewBox="0 0 32 32">
        <path d="M23.85 6.222l2.988 1.536a2 2 0 011.085 1.779v3.41a2 2 0 01-1.016 1.741l-3.131 1.768c-.06.034-.12.064-.182.091l.041 3.087a2 2 0 01-.975 1.745l-6.83 4.072a2 2 0 01-2.021.016l-6.805-3.91A2 2 0 016 19.814l.033-7.29a2 2 0 011.02-1.734l6.672-3.751a2 2 0 011.94-.012l2.347 1.284c.207-.3.495-.546.843-.699L22.13 6.17a2 2 0 011.72.052zM11.469 18.829v2.986l2.87 1.65.031-2.985-2.9-1.651zm6.475.142l-2.574 1.592-.031 2.85 2.514-1.498.091-2.944zm3.652-2.258l-2.632 1.627-.092 2.968 2.765-1.647-.041-2.948zm-13.582.15L8 19.823l2.468 1.417v-2.98l-2.455-1.397zm10.057-1.942l-2.658 1.547-.031 2.912 2.599-1.608.09-2.851zm-6.602-.002v2.76l2.914 1.657.03-2.873-2.944-1.544zm7.599.06l-.066 2.162 1.899-1.174-1.833-.988zM8.03 13.117l-.011 2.598 2.45 1.394-.001-2.714-2.438-1.278zm6.53-.502l-2.516 1.477 2.88 1.51 2.46-1.439-2.824-1.548zM25.922 10.1l-2.64 1.51.001 2.827 2.64-1.49V10.1zm-6.21.327l.027 2.626 2.518 1.353-.001-2.783-2.56-1.45c.011.084.017.169.017.254zm-8.53.337l-2.648 1.488L11 13.545l2.539-1.49-2.357-1.292zm6.479.031l-2.097 1.231 2.103 1.153c-.004-.05-.006-.1-.006-.151v-2.233zm-2.956-2.012l-2.496 1.403 2.334 1.28 2.445-1.435-2.283-1.248zM22.935 8l-2.83 1.247 2.665 1.509 2.606-1.501L22.936 8z" />
      </svg>
    </Icon>
  );
};

export default IconRubix;
