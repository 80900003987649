import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSidePanelOpen: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "SidePanelOpen"}>
      <svg viewBox="0 0 32 32">
        <path d="M26 15a1 1 0 010 2H13a1 1 0 010-2zm-3-7a1 1 0 010 2H9a1 1 0 110-2zm0 14a1 1 0 010 2H9a1 1 0 010-2zm2.707-9.707l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L26.586 16l-2.293-2.293a1 1 0 011.414-1.414zM9 15a1 1 0 110 2 1 1 0 010-2z" />
      </svg>
    </Icon>
  );
};

export default IconSidePanelOpen;
