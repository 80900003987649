import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconBell: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "bell"}>
      <svg viewBox="0 0 32 32">
        <path d="M15 24a1 1 0 002 0h2a3 3 0 01-6 0zm1-19c.552 0 1 .443 1 .999v1.002l-.002.045C21.115 7.46 24 10.65 24 15v2c0 1.67.22 2.306.747 2.898.046.052.095.105.204.223C25.69 20.931 26 21.644 26 23a1 1 0 01-1 1H7a1 1 0 01-1-1c0-1.356.31-2.07 1.05-2.879l.203-.223C7.779 19.306 8 18.671 8 17v-2c0-4.318 2.866-7.476 7.003-7.94A.4.4 0 0115 7V5.999A.997.997 0 0116 5zm.077 4C12.467 9 10 11.43 10 15v2c0 2.142-.386 3.252-1.253 4.227-.057.065-.12.132-.222.244-.178.195-.3.35-.38.529H23.855c-.08-.178-.202-.334-.38-.53a18.91 18.91 0 01-.222-.243C22.386 20.252 22 19.142 22 17v-2c0-3.564-2.428-6-5.923-6z" />
      </svg>
    </Icon>
  );
};

export default IconBell;
