import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconArrowRight: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "arrow right"}>
      <svg viewBox="0 0 32 32">
        <path d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z" />
      </svg>
    </Icon>
  );
};

export default IconArrowRight;
