import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconShare: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "share"}>
      <svg viewBox="0 0 32 32">
        <path d="M11.5 7a3.5 3.5 0 013.392 4.367l4.247 2.55a3.5 3.5 0 110 5.167l-4.247 2.548a3.5 3.5 0 11-1.03-1.715l4.246-2.549a3.507 3.507 0 010-1.737l-4.247-2.548A3.5 3.5 0 1111.5 7zm0 14a1.5 1.5 0 101.493 1.644l-.02.138a1.508 1.508 0 00-.387-1.316l.026.027a1.509 1.509 0 00-.254-.223l.058.042a1.504 1.504 0 00-.114-.08l.056.038a1.502 1.502 0 00-.095-.062l.04.024a1.498 1.498 0 00-.12-.068l.08.044a1.492 1.492 0 00-.318-.141l-.024-.007A1.5 1.5 0 0011.5 21zm10-6c-.169 0-.331.028-.483.08l.039-.013a1.49 1.49 0 00-.318.14l.078-.042c-.04.02-.08.043-.118.067l.04-.024c-.033.02-.065.04-.097.062l.057-.038a1.504 1.504 0 00-.113.08l.056-.042a1.506 1.506 0 00-.112.086l.056-.044a1.508 1.508 0 00-.101.085l.045-.04a1.51 1.51 0 00-.1.092l.055-.052a1.51 1.51 0 00-.097.097l.042-.045a1.506 1.506 0 00-.37.635l-.032.131A1.508 1.508 0 0020 16.5l.005.131.002.012a1.49 1.49 0 00.38.863l-.032-.037c.024.028.048.055.073.08l-.04-.043c.03.034.062.066.095.097l-.055-.054c.034.035.07.068.106.099l-.05-.045c.031.03.065.058.1.085l-.05-.04c.03.025.06.049.093.072l-.043-.032a1.501 1.501 0 00.305.182l-.078-.037c.04.02.08.04.122.056l-.044-.019c.049.022.099.041.15.058l-.106-.039A1.5 1.5 0 1021.5 15zm-10-6a1.5 1.5 0 10.688 2.833l-.057.028c.039-.018.077-.037.113-.058l-.056.03c.04-.02.077-.042.114-.065l-.058.035c.042-.024.083-.05.122-.078l-.064.043c.039-.025.076-.05.112-.078l-.048.035c.037-.026.074-.055.109-.085l-.061.05c.035-.028.07-.056.102-.086l-.041.036a1.51 1.51 0 00.096-.09l-.055.054a1.51 1.51 0 00.097-.098l-.042.045a1.503 1.503 0 00.417-1.244A1.5 1.5 0 0011.5 9z" />
      </svg>
    </Icon>
  );
};

export default IconShare;
