import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconStudent: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "student"}>
      <svg viewBox="0 0 32 32">
        <path d="M15.243 8.16a1.875 1.875 0 011.514 0l10.622 4.66c.828.363.828 1.621 0 1.985L26 15.409l.001 4.89c.212.074.396.223.522.424l.062.116.5 1.073c.247.53.047 1.174-.447 1.439a.945.945 0 01-.447.113H25.5c-.552 0-1-.48-1-1.073V21.32c0-.397.201-.744.5-.93v-4.542l-2 .878v2.855c0 1.103-.526 2.124-1.386 2.712l-.165.105C19.651 23.461 17.831 24 16 24c-1.83 0-3.65-.539-5.45-1.603-.9-.533-1.479-1.52-1.544-2.611L9 19.58v-2.855l-4.379-1.92c-.789-.347-.826-1.504-.112-1.928l.112-.058zM25.69 21.319H25.5v1.072h.691l-.5-1.072zM21 17.602l-4.243 1.862a1.875 1.875 0 01-1.514 0L11 17.603v1.977c0 .304.12.59.323.79l.092.08.102.07c1.512.894 3.003 1.335 4.483 1.335 1.48 0 2.97-.44 4.483-1.336.284-.168.472-.47.51-.81L21 19.58v-1.978zm-5.001-7.458l-8.358 3.668 8.358 3.667 8.359-3.667-8.359-3.668z" />
      </svg>
    </Icon>
  );
};

export default IconStudent;
