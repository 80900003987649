import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSoftware: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "software"}>
      <svg viewBox="0 0 32 32">
        <path d="M10.192 4.023l.166.023 11 2a2 2 0 011.636 1.808l.006.16v2A1 1 0 0121 10l.001.013H21v-2l-11-2v20l1.455-.397c.06-.017.123-.03.187-.038l.117-.006a1 1 0 01.222 1.975l.026-.008c-.415.114-1.462.475-3.006.475-1.03 0-1.834-.195-2.415-.586l-2-2a2 2 0 01-.578-1.238L4 24.014v-15a2 2 0 01.467-1.284l.119-.13 2-2c.483-.484.722-.628 1.414-1.09.692-.464 1.42-.563 2.192-.487zM20 12.013a8 8 0 110 16 8 8 0 010-16zm-12-5l-2 2v15l2 2v-19zm12 7a6 6 0 100 12 6 6 0 000-12zm0 3a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z" />
      </svg>
    </Icon>
  );
};

export default IconSoftware;
