import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconArrowRightSmall: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "arrow right_small"}>
      <svg viewBox="0 0 32 32">
        <path d="M23 16v-.02c0-.023-.002-.046-.004-.07L23 16a1.008 1.008 0 00-.213-.617l-.007-.008-.073-.082-6-6a1 1 0 00-1.414 1.414L19.586 15H10a1 1 0 000 2h9.586l-4.293 4.293a1 1 0 101.414 1.414l6-6 .073-.082.007-.008-.08.09a1.008 1.008 0 00.293-.689V16z" />
      </svg>
    </Icon>
  );
};

export default IconArrowRightSmall;
