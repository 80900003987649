import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCalendar: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "calendar"}>
      <svg viewBox="0 0 32 32">
        <path d="M20 6a1 1 0 011 1v1h3a2 2 0 012 2v8.586A2 2 0 0125.414 20L20 25.414a2 2 0 01-1.414.586H8a2 2 0 01-2-2V10a2 2 0 012-2h3V7a1 1 0 012 0v1h6V7a1 1 0 011-1zm4 9H8v9h10v-4a2 2 0 012-2h4v-3zm-1.415 5H20v2.585L22.585 20zM11 10H8v3h16v-3h-3a1 1 0 01-2 0h-6a1 1 0 01-2 0z" />
      </svg>
    </Icon>
  );
};

export default IconCalendar;
