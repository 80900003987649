import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSubtract: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "subtract"}>
      <svg viewBox="0 0 32 32">
        <path d="M8 17h16a1 1 0 000-2H8a1 1 0 000 2z" />
      </svg>
    </Icon>
  );
};

export default IconSubtract;
