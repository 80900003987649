import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCohort: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "cohort"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 13a4 4 0 013.841 5.119 4.746 4.746 0 011.431 2.08l.08.252.389 1.362c.02.068.036.136.05.205a2.499 2.499 0 01-.127 4.977L21.5 27h-11a2.5 2.5 0 01-.29-4.983l.022-.102.027-.102.39-1.362a4.75 4.75 0 011.51-2.333A4 4 0 0116 13zm5.5 11h-11a.5.5 0 00-.09.992l.09.008h11l.09-.008a.5.5 0 000-.984L21.5 24zm-4.723-5h-1.554c-.739 0-1.424.294-1.927.785l-.082.085c-.246.26-.442.57-.572.917l-.07.213-.39 1.363a.5.5 0 00-.014.068l-.005.069a.5.5 0 00.41.492l.09.008h6.674a.5.5 0 00.498-.549l-.017-.088-.39-1.363a2.752 2.752 0 00-.642-1.131l-.082-.084a2.756 2.756 0 00-1.727-.778l-.2-.007zM20.5 5a3.5 3.5 0 013.203 4.913 4.637 4.637 0 011.67 2.357l.067.245.345 1.379c.01.042.02.084.028.126a2.499 2.499 0 01-.149 4.975L25.5 19H22l-.117-.007a1 1 0 010-1.986L22 17h3.5l.09-.008a.5.5 0 000-.984L25.5 16H21l-.09-.008a.5.5 0 010-.984L21 15h2.36a.5.5 0 00.499-.532l-.014-.09L23.5 13a2.64 2.64 0 00-2.39-1.994L20.938 11h-.876a2.64 2.64 0 00-2.07 1h-3.985a2.64 2.64 0 00-1.897-.994L11.938 11h-.876a2.64 2.64 0 00-2.515 1.835L8.5 13l-.345 1.379a.5.5 0 00.396.613l.09.008H11a.5.5 0 01.492.41l.008.09-.001.037-.004.037a.5.5 0 01-.403.418L11 16H6.5a.5.5 0 00-.09.992L6.5 17H10a1 1 0 01.993.883L11 18l-.003.075-.008.073a1 1 0 01-.875.846L10 19H6.5a2.5 2.5 0 01-.312-4.98l.027-.126.345-1.38a4.634 4.634 0 011.738-2.601 3.5 3.5 0 116.406 0A4.65 4.65 0 0116 11.396a4.63 4.63 0 011.298-1.483A3.5 3.5 0 0120.5 5zM16 15a2 2 0 101.978 2.296l.017-.147L18 17a2 2 0 00-2-2zm-4.5-8a1.5 1.5 0 101.339 2.177l.037-.082A1.5 1.5 0 0011.5 7zm9 0a1.5 1.5 0 101.339 2.177l.037-.082A1.5 1.5 0 0020.5 7z" />
      </svg>
    </Icon>
  );
};

export default IconCohort;
