import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconAuto: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "auto"}>
      <svg viewBox="0 0 32 32">
        <path d="M14.293 3.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414l1.28-1.282A8 8 0 1024 16l.007-.117A1 1 0 0126 16c0 5.523-4.477 10-10 10S6 21.523 6 16c0-5.386 4.259-9.778 9.593-9.992l-1.3-1.3a1 1 0 010-1.415zm4 9a1 1 0 011.414 1.414L17 16.414V21a1 1 0 01-.883.993L16 22a1 1 0 01-1-1v-5a1.02 1.02 0 01.125-.484.878.878 0 01.071-.111.999.999 0 01.097-.112l-.08.09c.025-.031.051-.062.08-.09z" />
      </svg>
    </Icon>
  );
};

export default IconAuto;
