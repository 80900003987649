import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconLogout: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "logout"}>
      <svg viewBox="0 0 32 32">
        <path d="M25 6a1 1 0 011 1v18a1 1 0 01-1 1h-6a1 1 0 01-1-1v-3a1 1 0 012 0v2h4V8h-4v2a1 1 0 01-2 0V7a1 1 0 011-1zm-13.707 4.293a1 1 0 011.414 1.414L9.414 15H21a1 1 0 01.993.883L22 16a1 1 0 01-1 1H9.414l3.293 3.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z" />
      </svg>
    </Icon>
  );
};

export default IconLogout;
