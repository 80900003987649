import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconEdit: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "edit"}>
      <svg viewBox="0 0 32 32">
        <path d="M15 6a1 1 0 01.117 1.993L15 8h-5a2 2 0 00-1.995 1.85L8 10v12a2 2 0 001.85 1.995L10 24h12a2 2 0 001.995-1.85L24 22v-3l.007-.117a1 1 0 01.876-.876L25 18a1 1 0 01.993.883L26 19v3a4 4 0 01-3.8 3.995L22 26H10a4 4 0 01-3.995-3.8L6 22V10a4 4 0 013.8-3.995L10 6h5zm9.669-.294l.11.102 1.413 1.414a2 2 0 01.102 2.719l-.102.11-1.414 1.413a1.99 1.99 0 01-.896.518 1.98 1.98 0 01-.396.763l-.122.134-7.354 7.354a2 2 0 01-.782.483l-.18.05-1.836.427a2 2 0 01-2.432-2.236l.03-.16.422-1.84a2 2 0 01.41-.829l.125-.138 7.354-7.354a1.99 1.99 0 01.898-.518c.072-.273.204-.535.395-.763l.122-.133 1.414-1.414a2 2 0 012.719-.102zm-4.133 4.344l-7.354 7.354-.422 1.84 1.836-.426 7.354-7.354-1.414-1.414zm2.828-2.828L21.95 8.636l1.414 1.414 1.414-1.414-1.414-1.414z" />
      </svg>
    </Icon>
  );
};

export default IconEdit;
