import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconMoreHorizontal: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "more horizontal"}>
      <svg viewBox="0 0 32 32">
        <path d="M21 16a2 2 0 114 0 2 2 0 01-4 0zm-7 0a2 2 0 114 0 2 2 0 01-4 0zm-7 0a2 2 0 114 0 2 2 0 01-4 0z" />
      </svg>
    </Icon>
  );
};

export default IconMoreHorizontal;
