import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconPiechart: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "piechart"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 6a1 1 0 011 1v8h8a1 1 0 011 1c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm-1 2.062A8.001 8.001 0 0016 24a8.001 8.001 0 007.938-7H16a1 1 0 01-1-1V8.062zM20 6a6 6 0 016 6 1 1 0 01-1 1h-5a1 1 0 01-1-1V7a1 1 0 011-1zm1 2.126V11h2.874A4.007 4.007 0 0021 8.126z" />
      </svg>
    </Icon>
  );
};

export default IconPiechart;
