import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconClose: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "close"}>
      <svg viewBox="0 0 32 32">
        <path d="M9.293 9.293a1 1 0 011.414 0L16 14.585l5.293-5.292a1 1 0 011.32-.083l.094.083a1 1 0 010 1.414L17.415 16l5.292 5.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L16 17.415l-5.293 5.292a1 1 0 01-1.32.083l-.094-.083a1 1 0 010-1.414L14.585 16l-5.292-5.293a1 1 0 01-.083-1.32z" />
      </svg>
    </Icon>
  );
};

export default IconClose;
