import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconSearch: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "search"}>
      <svg viewBox="0 0 32 32">
        <path d="M15 6a9 9 0 017.032 14.617l4.504 4.504a1 1 0 01-1.415 1.415l-4.504-4.504A9 9 0 1115 6zm0 2a7 7 0 100 14 7 7 0 000-14z" />
      </svg>
    </Icon>
  );
};

export default IconSearch;
