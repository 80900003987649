import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCourse: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "course"}>
      <svg viewBox="0 0 32 32">
        <path d="M11.859 11.486l6 10A1 1 0 0117.002 23h-12a1 1 0 01-.858-1.514l6-10a1 1 0 011.715 0zm13 5l3 5A1 1 0 0127.002 23h-6a1 1 0 01-.858-1.514l3-5a1 1 0 011.715 0zm-13.857-2.542L6.768 21h8.467l-4.233-7.056zm13 5L22.768 21h2.467l-1.233-2.056zm-2-9.944a1 1 0 01.857 1.514l-3 5a1 1 0 01-1.715 0l-3-5A1 1 0 0116.002 9h6zm-1.767 2h-2.467l1.234 2.056L20.235 11z" />
      </svg>
    </Icon>
  );
};

export default IconCourse;
