import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconBookOpen: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "book open"}>
      <svg viewBox="0 0 32 32">
        <path d="M24.238 7.76a2 2 0 01.095.61V9H27a1 1 0 011 1v13a1 1 0 01-1 1H5a1 1 0 01-1-1V10a1 1 0 011-1h2.666v-.63a2 2 0 012.61-1.905L16 8.6l5.724-2.134a2 2 0 012.514 1.295zM7.666 11H6v11h7.108l-4.05-1.492a2 2 0 01-1.391-1.905L7.666 11zM26 11h-1.667v7.603a2 2 0 01-1.39 1.905L18.89 22 26 22V11zM9.667 8.37v10.233L15 20.63V10.397L9.667 8.37zm12.666 0L17 10.397V20.63l5.333-2.027V8.37z" />
      </svg>
    </Icon>
  );
};

export default IconBookOpen;
