import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconDownload: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "download"}>
      <svg viewBox="0 0 32 32">
        <path d="M25 24a1 1 0 010 2H7a1 1 0 010-2h18zM16 6a1 1 0 011 1v11.585l3.293-3.292a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L15 18.585V7a1 1 0 011-1z" />
      </svg>
    </Icon>
  );
};

export default IconDownload;
