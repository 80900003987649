import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCaretLeft: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "caret left"}>
      <svg viewBox="0 0 32 32">
        <path d="M20 11.902v8.196a.5.5 0 01-.765.424l-6.557-4.098a.5.5 0 010-.848l6.557-4.098a.5.5 0 01.765.424z" />
      </svg>
    </Icon>
  );
};

export default IconCaretLeft;
