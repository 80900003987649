import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCareers: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "careers"}>
      <svg viewBox="0 0 32 32">
        <path d="M19.003 6C20.11 6 21 6.895 21 8.01V10h4.008c1.104 0 1.992.901 1.992 2.007v11.986A2.007 2.007 0 0125.008 26H6.992A1.999 1.999 0 015 23.993V12.007C5 10.904 5.898 10 6.992 10H11V8.01C11 6.903 11.897 6 12.997 6h6.006zm5.996 8.296l-.119.123C22.7 16.796 19.72 18 16 18c-3.79 0-6.81-1.248-9-3.714l.001 1.524V21.52L7 23.993l.097.001h.374l.09.001h.7l.145.001h1.227l.206.001 2.208.001h.285l3.64.001h.379l8.131.001h.526l-.002-.1v-.094l-.002-.202v-.175l-.001-.099v-.222l-.001-.123v-.416L25 22.414v-.511L25 21.716v-1.284l-.001-.242v-5.325-.569zm-9.35-2.295H7.746C9.599 14.683 12.318 16 16 16c3.68 0 6.398-1.316 8.251-3.995h.028-.54l-.145-.001h-1.227l-.206-.001-2.208-.001h-.285l-3.64-.001h-.379zM19.002 8h-6.005c.008 0 .003.005.003.01v1.98c0 .007 0 .01-.002.01h6.005c-.008 0-.003-.005-.003-.01V8.01c0-.007 0-.01.002-.01z" />
      </svg>
    </Icon>
  );
};

export default IconCareers;
