import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconCompass: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "compass"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 4a1 1 0 011 1v.045A11.003 11.003 0 0126.956 15H27a1 1 0 010 2h-.045a11.003 11.003 0 01-9.954 9.955L17 27a1 1 0 01-2 0v-.045a11.003 11.003 0 01-9.955-9.954L5 17a1 1 0 010-2h.045A11.003 11.003 0 0115 5.045V5a1 1 0 011-1zm0 3a9 9 0 100 18 9 9 0 000-18zm4.949 5.316l-2 6a1 1 0 01-.633.633l-6 2a1 1 0 01-1.265-1.265l2-6a1 1 0 01.633-.633l6-2a1 1 0 011.265 1.265zm-2.53 1.265l-3.628 1.21-1.21 3.628 3.628-1.21 1.21-3.628z" />
      </svg>
    </Icon>
  );
};

export default IconCompass;
