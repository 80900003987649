import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconGraphDown: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "graph down"}>
      <svg viewBox="0 0 32 32">
        <path d="M8.707 9.293L14 14.586l2.293-2.293a1 1 0 011.46.048L24 19.482V18a1 1 0 012 0v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.671l-5.72-6.537-2.244 2.244a1 1 0 01-1.414 0l-6-6a1 1 0 011.414-1.414z" />
      </svg>
    </Icon>
  );
};

export default IconGraphDown;
