import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconMegaphone: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "megaphone"}>
      <svg viewBox="0 0 32 32">
        <path d="M19.91 6.006l7 12.124a1 1 0 01-.623 1.47l-7.794 1.951.715 1.238c.57.988.157 2.158-.872 2.582l-.138.05-2.933.97c-.977.323-2.125-.112-2.64-1.003l-1.072-1.857-2.597 1.5a1 1 0 01-1.366-.365l-2.5-4.33a1 1 0 01.366-1.366l3.314-1.914 9.512-11.198a1 1 0 011.628.148zm-6.462 16.808l.91 1.574c.04.069.2.13.28.104l2.724-.9-.887-1.536-3.027.758zm5.44-14.58l-8.25 9.712 1.77 3.066 12.107-3.03-5.627-9.747zM10.553 21.8l-1.5-2.598-1.731 1 1.5 2.599 1.731-1z" />
      </svg>
    </Icon>
  );
};

export default IconMegaphone;
