import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconGauge: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "gauge"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 8c5.857 0 10.663 4.584 10.984 10.413l.012.293.004.265a2 2 0 01-1.85 2.023L25 21h-3l-.117-.007a1 1 0 010-1.986L22 19h3l-.004-.265a9 9 0 00-17.98-.264l-.012.264L7 19h5.126a4.002 4.002 0 015.906-2.446l2.21-2.21a1 1 0 011.415 1.413l-2.21 2.211a4 4 0 11-7.32 3.033L7 21a2 2 0 01-1.997-1.878L5 18.972l.005-.295C5.177 12.738 10.045 8 16 8zm0 10a2 2 0 100 4 2 2 0 000-4z" />
      </svg>
    </Icon>
  );
};

export default IconGauge;
