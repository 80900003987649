import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconGraphUp: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "graph up"}>
      <svg viewBox="0 0 32 32">
        <path d="M25 9a1 1 0 011 1v4a1 1 0 01-2 0v-1.483l-6.247 7.142a1 1 0 01-1.46.048L14 17.414l-5.293 5.293a1 1 0 11-1.414-1.414l6-6a1 1 0 011.414 0l2.244 2.244L22.67 11H21a1 1 0 010-2h4z" />
      </svg>
    </Icon>
  );
};

export default IconGraphUp;
