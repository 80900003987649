import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconLanguage: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "language"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 4c6.627 0 12 5.373 12 12s-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4zm3.708 17h-7.416c.463 1.543 1.128 3.161 2 4.853a10.065 10.065 0 003.417.002c.87-1.694 1.537-3.312 2-4.855zm-9.496 0L7.338 21a10.044 10.044 0 004.33 4.015A27.743 27.743 0 0110.212 21zm14.45.001L21.789 21a27.68 27.68 0 01-1.457 4.014 10.034 10.034 0 004.33-4.013zm-15.042-7L6.2 14a10.046 10.046 0 00.258 5h3.307a17.673 17.673 0 01-.265-3c0-.654.04-1.32.12-2zM20.365 14h-8.73c-.09.683-.135 1.35-.135 2 0 .963.1 1.963.298 3h8.404c.199-1.037.298-2.037.298-3 0-.65-.045-1.317-.135-2zm5.435 0h-3.42c.08.68.12 1.346.12 2 0 .974-.088 1.974-.265 3h3.307a10.046 10.046 0 00.258-5zM11.668 6.986l-.1.047a10.039 10.039 0 00-4.736 4.968h3.131c.36-1.609.929-3.28 1.705-5.015zM16 6c-.582 0-1.153.05-1.708.145l.095-.182c-1.112 2.13-1.9 4.142-2.37 6.038h7.965c-.469-1.896-1.257-3.908-2.368-6.038l.094.182A10.065 10.065 0 0016 6zm4.333.985l.003.01c.774 1.731 1.342 3.4 1.701 5.006h3.131a10.039 10.039 0 00-4.835-5.016z" />
      </svg>
    </Icon>
  );
};

export default IconLanguage;
