import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconTrash: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "trash"}>
      <svg viewBox="0 0 32 32">
        <path d="M18.01 6C19.113 6 20 6.897 20 8.01V10h5a1 1 0 01.993.883L26 11a1 1 0 01-.883.993L25 12h-1v11.993A2.006 2.006 0 0121.993 26H10.007A2.006 2.006 0 018 23.993V12H7a1 1 0 01-.993-.883L6 11a1 1 0 01.883-.993L7 10h5V8.01C12 6.904 12.893 6 13.99 6h4.02zM22 12.007L10.007 12 10 23.993 21.993 24 22 12.007zM13 14a1 1 0 01.993.883L14 15v6a1 1 0 01-1.993.117L12 21v-6a1 1 0 011-1zm6 0a1 1 0 01.993.883L20 15v6a1 1 0 01-1.993.117L18 21v-6a1 1 0 011-1zm-.998-6h-4.011l.005.164L14 9.991c0 .005 0 .008-.002.009h4.011L18 9.997 18 8.01c0-.005 0-.008.002-.009z" />
      </svg>
    </Icon>
  );
};

export default IconTrash;
