import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconHistory: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "history"}>
      <svg viewBox="0 0 32 32">
        <path d="M16 6l.117.007a1 1 0 010 1.986L16 8a8 8 0 108 8l.007-.117a1 1 0 011.986 0L26 16c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 3a1 1 0 011 1v5.422l2.964 1.712a1 1 0 01-1 1.732l-3.464-2-.013-.007a1.001 1.001 0 01-.07-.047l-.015-.01-.027-.022a1.005 1.005 0 01-.335-.5l-.008-.03a.864.864 0 01-.028-.163.99.99 0 01-.004-.076V10a1 1 0 011-1zm8.461 2.493a1 1 0 110 2 1 1 0 010-2zm-1.933-2.987a1 1 0 110 2 1 1 0 010-2zm-3.011-2.013a1 1 0 110 2 1 1 0 010-2z" />
      </svg>
    </Icon>
  );
};

export default IconHistory;
