import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconFolderAdd: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "folder add"}>
      <svg viewBox="0 0 32 32">
        <path d="M14.002 6c.818 0 1.832.42 2.411.999l2.588 2.588c.133.133.239.272.319.414L25.008 10c1.104 0 1.992.901 1.992 2.007v11.986A2.007 2.007 0 0125.008 26H6.992A1.999 1.999 0 015 23.993V8.01C5 6.902 5.891 6 6.998 6h7.004zm1.647 6.001L6.992 12l.002.1v.094l.002.202v.175l.001.099v.222l.001.123v.416l.001.155v.511l.001.187v1.284l.001.242V21.52L7 23.993l.097.001h.374l.09.001h.7l.145.001h1.227l.206.001 2.208.001h.285l3.64.001h.379l8.131.001h.526l-.002-.1v-.094l-.002-.202v-.175l-.001-.099v-.222l-.001-.123v-.416L25 22.414v-.511L25 21.716v-1.284l-.001-.242v-5.325-.384L25 12.007l-.097-.001h-.374l-.09-.001h-.7l-.145-.001h-1.227l-.206-.001-2.208-.001h-.285l-3.64-.001h-.379zM16 14a1 1 0 011 1v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2h-2a1 1 0 010-2h2v-2a1 1 0 011-1zm-1.998-6H6.998L7 8.002V10h9.586l-1.587-1.587c-.204-.204-.71-.413-.997-.413z" />
      </svg>
    </Icon>
  );
};

export default IconFolderAdd;
