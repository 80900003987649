import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconFile: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "file"}>
      <svg viewBox="0 0 32 32">
        <path d="M22 6a2 2 0 012 2v10.64a2 2 0 01-.59 1.419l-5.394 5.36a2 2 0 01-1.41.581H10a2 2 0 01-2-2V8a2 2 0 012-2h12zm0 2H10v16h6v-4.01c0-1.097.897-1.99 1.99-1.99H22V8zm-1.414 12H18v2.586L20.586 20zM19 14a1 1 0 010 2h-6a1 1 0 010-2zm0-4a1 1 0 010 2h-6a1 1 0 010-2z" />
      </svg>
    </Icon>
  );
};

export default IconFile;
